import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const PaginationStyles = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-items: center;
  border: 1px solid var(--grey);
  margin: 2rem 0;
  border-radius: 5px;
  text-align: center;
  &a {
    pointer-events: none;
    color: var(--grey);
  }
  & > * {
    padding: 1rem;
    flex: 1;
    border-right: 1px solid var(--grey);
    text-decoration: none;
    &[aria-current],
    &.current {
      color: var(--red);
    }
    &[disabled] {
      pointer-events: none;
      color: var(--grey);
  }
  @media(max-width: 800px){
    .word {
      display:none;
    }
    font-size:1.4rem;
    padding: .2rem;
  }
`;

const Pagination = ({
  pageSize,
  totalCount,
  currentPage = 1,
  base,
  maxLinks = 9,
}) => {
  const totalPages = Math.ceil(totalCount / pageSize);
  const beforeAfter = Math.floor(Math.min(maxLinks - 1, totalPages) / 2);
  const numberOfLinks = Math.min(totalPages, maxLinks);
  const firstLink = Math.min(
    Math.max(1, currentPage - beforeAfter),
    totalPages - numberOfLinks + 1
  );

  return (
    <PaginationStyles>
      <Link
        disabled={currentPage === 1 && firstLink === 1}
        to={currentPage > 2 ? `/${base}/${currentPage - 1}` : `/${base}`}
      >
        ←{' '}
        <span title="Previous page" className="word">
          Previous
        </span>
      </Link>
      {Array.from({ length: numberOfLinks }, (_, i) => (
        <Link
          className={currentPage === 1 && i === 0 ? 'current' : ''}
          style={
            (i === 0 && firstLink !== 1) ||
            (i === numberOfLinks - 1 && firstLink + numberOfLinks <= totalPages)
              ? { pointerEvents: 'none' }
              : null
          }
          to={i + firstLink !== 1 ? `/${base}/${i + firstLink}` : `/${base}`}
          key={i + firstLink}
        >
          {(i === 0 && firstLink !== 1) ||
          (i === numberOfLinks - 1 && firstLink + numberOfLinks <= totalPages)
            ? '...'
            : i + firstLink}
        </Link>
      ))}
      <Link
        disabled={currentPage >= totalPages}
        to={`/${base}/${currentPage + 1}`}
      >
        <span title="next page" className="word">
          Next{' '}
        </span>
        →
      </Link>
    </PaginationStyles>
  );
};
export default Pagination;
