import { graphql, Link } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Pagination from '../components/Pagination';
import SEO from '../components/SEO';

const SlicemasterGridStyles = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`;

const SlicemasterStyles = styled.div`
  a {
    text-decoration: none;
  }
  .gatsby-image-wrapper {
    height: 400px;
  }
  h2 {
    transform: rotate(-2deg);
    text-align: center;
    font-size: 4rem;
    margin-bottom: -2rem;
    position: relative;
    z-index: 2;
  }
  .description {
    background: var(--yellow);
    padding: 1rem;
    margin: 2rem;
    margin-top: -6rem;
    z-index: 2;
    position: relative;
    transform: rotate(1deg);
    text-align: center;
  }
`;

const SlicemastersPage = ({
  data: {
    slicemasters: { totalCount, people },
  },
  pageContext,
}) => (
  <>
    <SEO title={`Slicemasters - Page ${pageContext.currentPage || 1}`} />
    <Pagination
      pageSize={parseInt(process.env.GATSBY_SLICEMASTERS_PAGE_SIZE)}
      base="slicemasters"
      currentPage={pageContext.currentPage || 1}
      totalCount={totalCount}
      skip={pageContext.skip}
    />
    <SlicemasterGridStyles>
      {people
        .slice(0, parseInt(process.env.GATSBY_SLICEMASTERS_PAGE_SIZE))
        .map((person) => (
          <SlicemasterStyles key={person.id}>
            <Link to={`/slicemaster/${person.slug.current}`}>
              <h2>
                <span className="mark">{person.name}</span>
              </h2>
            </Link>
            <Img fluid={person.image.asset.fluid} />
            <p className="description">{person.description}</p>
          </SlicemasterStyles>
        ))}
    </SlicemasterGridStyles>
  </>
);
export default SlicemastersPage;

export const query = graphql`
  query PersonQuery($skip: Int = 0, $pageSize: Int) {
    slicemasters: allSanityPerson(
      limit: $pageSize
      skip: $skip
      sort: { fields: name, order: ASC }
    ) {
      totalCount
      people: nodes {
        id
        name
        description
        slug {
          current
        }
        image {
          asset {
            fluid(maxWidth: 410) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
